// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ui/layout/v2_ring_buttons.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/ui/layout/v2_scroll.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".deactive[data-v-acac4e1a]{filter:grayscale(100%);pointer-events:none}.three_buttons_background[data-v-acac4e1a]{position:absolute;width:25.250000000000004%;height:40.11111111111111%;display:block;margin:auto;overflow:visible;top:0;right:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:transparent;background-position:50%;background-repeat:no-repeat;background-size:cover}.blackout_bonus[data-v-acac4e1a]{position:fixed;z-index:1}#buttonGear[data-v-acac4e1a]{position:absolute;width:8.534400000000002%;height:15.371377777777779%;right:.6825%;top:21.093333333333334%}#buttonShop[data-v-acac4e1a]{position:absolute;top:2.955555555555556%;right:11.742500000000001%;width:12.705000000000004%;height:22.58666666666667%}#buttonGame[data-v-acac4e1a]{right:0;width:27.125000000000004%;height:55.33333333333333%}#buttonGame[data-v-acac4e1a],#buttonHelp[data-v-acac4e1a]{position:absolute;bottom:0}#buttonHelp[data-v-acac4e1a]{width:21.259259259259263%;height:23.275720164609055%;top:8.088888888888889%;right:2.800000000000001%}.buttonScroll[data-v-acac4e1a]{position:absolute;display:block;margin:auto;overflow:visible;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");top:7.155555555555555%;right:2.45%;width:9.139%;height:13.173333333333332%}#buttonExit[data-v-acac4e1a]{position:absolute;left:.37485%;top:.444266666666667%;width:5.124875000000001%;height:9.555466666666668%}#buttonChat[data-v-acac4e1a]{width:15.062495%;height:12.777644444444444%;padding:0;position:absolute;left:0;right:0;bottom:0;margin:auto}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
