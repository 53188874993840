
























import { NuxtVueI18n } from 'nuxt-i18n'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

@Component({        
    computed: {},
})
export default class MainPage extends Vue {
    showList:boolean = false;
    timer:any = null;

    toggleList() {        
        //@ts-ignore
        ym_goal('change_lang')

        this.showList = !this.showList;
        if(this.showList)
            this.resetTimer();
    }
    
    get localeCode3() { 
        let locale:any = this.localeCurrent
        return locale.code3
    }

    get localeCurrent() {    
        return this.localesAll.find( i => i.code == this.$i18n.locale ) ;                  
    }

    get localesAll() {
        return <NuxtVueI18n.Options.LocaleObject[]>this.$i18n.locales
    }

    get localesOther() {
        return this.localesAll.filter((i) => i.code !== this.$i18n.locale)
    }

    resetTimer() {
        if(this.timer) {
            clearTimeout(this.timer)
            this.timer = null;
        }

        this.timer = setTimeout( ()=>this.showList = false, 3000)
    }    

    setLanguage(language: string) {        
        let i18n = this.$i18n;
        if(!i18n) 
            return;

        this.resetTimer();
        
        console.debug("Set locale |"+ language+"|");
        i18n.setLocale(language);                
        i18n.setLocaleCookie(language);                   
        //this.$router.push(this.switchLocalePath(language)); 

        this.toggleList();
    }
}
