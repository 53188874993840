












const ISMAIL = process.env.MAILRU_GAME ? true : false;
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
@Component({
    data: () => ({
        isMail: ISMAIL
    })    
})
export default class LayoutBackground extends Vue {
    //isMail: boolean = ISMAIL
}
