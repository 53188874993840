



















import Vue from 'vue'
import {mapState} from 'vuex'
export default Vue.extend({
    computed:{
        ...mapState({
            platform:"platform",
            showAdvicer:"showAdvicer"
        }),
        fbUrl() {
            return "https://play.google.com/store/apps/details?id=com.facebook.katana"
        },
        isMobileWeb() {
            // type Platform = "IOS" | "ANDROID" | "WEB" | "MOBILE_WEB";
            return this.platform == "MOBILE_WEB"
        },
    },    
    methods:{        
        cancel() {            
            this.$store.commit("SET_SHOW_ADVICER", false)            
        }        
    }
})
