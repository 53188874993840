







































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import Account from '~/modules/model/Types/Account'
import Player from '~/modules/model/Types/Player'
import {ECurrencyAbbreviation} from '~/modules/model/Types/Currency'

@Component({
    name: 'index',
    middleware: '',    
    computed: {
        ...mapState( {            
            player: "player",
        } ),
        ...mapState( "account", {
            account: "accountMain",            
        } ),
        ...mapState( 'account', {
            accountsMoney: 'accountsMoney',
        } ),
    },
    layout: 'mobileMenu',    
})
export default class MainPage extends Vue {
    declare account: Account | null
    declare player: Player | null
    declare accountsMoney: any

    filterByName: string | null = null
    filterTag: string | null = null

    get isPhoenixProject() {
        return process.env.PHOENIX_GAME=="1"
    }

    get creditsString() {        
        let r = this.account ? this.account.getStringValue() : ""
        return r
    }
    get dollarsString() {
        console.log( 'ACCOUNT:', this.account )
        if ( this.account && ! isNaN( this.account.value ) ) {
            return this.account.value.toString()
        }
        return ''
    }
    get diamondsString() {
        if ( this.accountsMoney && this.accountsMoney.length > 0 && ! isNaN( this.accountsMoney[ 1 ].value ) ) {
            return this.accountsMoney[ 1 ].value.toString()
        }
        return ''
    }

    get isInGameCurrency() {
        if ( this.account && this.account.currency && this.account.currency.code_name ) {
            return this.account.currency.code_name === ECurrencyAbbreviation.CREDIT || this.account.currency.code_name === ECurrencyAbbreviation.GOLD
        }
        else {
            return false
        }
    }

    get valueWithCurrency() {
        if ( this.account && this.account.valueWithCurrency ) {
            return this.account.valueWithCurrency
        }
        return ''
    }

    get playerName() {
        return this.player ? this.player.name : "";
    }
    
    created() {
        /*setInterval(
            () => {
                console.log( 'gold:', this.accountsMoney[ 1 ].valueWithCurrency )
            },
            1000
        )*/
    }
    destroyed() {}
    mounted() {}
}
