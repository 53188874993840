// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/ui/layout/v2_info.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn_help[data-v-2c3c80ae]{display:block;width:100%;height:100%;position:relative;top:28.282942557343667%;margin:auto;overflow:visible;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.btn_help[data-v-2c3c80ae]:after{right:0;top:0}.aspect_ratio_box:not(.device_mobile) .btn_help[data-v-2c3c80ae]:after{right:1.6em;top:.4em}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
