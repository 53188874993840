import { render, staticRenderFns } from "./ButtonGame.vue?vue&type=template&id=446acfa0&scoped=true&"
import script from "./ButtonGame.vue?vue&type=script&lang=ts&"
export * from "./ButtonGame.vue?vue&type=script&lang=ts&"
import style0 from "./ButtonGame.vue?vue&type=style&index=0&id=446acfa0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446acfa0",
  null
  
)

export default component.exports