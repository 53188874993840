







import Vue from 'vue'
import { Prop, Watch } from "vue-property-decorator";
import { Route } from 'vue-router';
export default Vue.extend({

})
